import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Waflash from "../components/waflash"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const o = post.frontmatter;
  const siteTitle = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const { previous, next } = pageContext
  
  const anchorStyle = {
    color: '#78757a',
    boxShadow: 'none',
  };

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`${o.title} - WebAssembly Flash Demo`}
        xdescription={ `WAFlash - WebAssembly AS2/AS3 Flash player Demo` || o.description || post.excerpt}
        image={ `${siteUrl}${o.thumbnail.publicURL}`}
      />
      <div>
        <div>
          <Waflash src={o.swf || o.swfUrl} width={o.width} height={o.height} />
        </div>
        <div>
          <h3
            style={{
              marginTop: '0.5rem',
              marginBottom: 0,
            }}
          >
            {o.title}
          </h3>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            by <a style={anchorStyle} href={o.authorUrl}>{o.author}</a> {o.from && 'from '} {o.from && (<a style={anchorStyle} href={o.fromUrl} target="_blank">{o.from}</a>
            )}
          </p>
        </div>
        <div style={{ lineHeight: '1.5rem' }} dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
      </div>
      {/* <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <a href={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </a>
            )}
          </li>
          <li>
            {next && (
              <a href={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </a>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

export default BlogPostTemplate

        //width
        //height

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        authorUrl
        from
        fromUrl
        swf {
          publicURL
        }
        swfUrl
        thumbnail {
          publicURL
        }
        width
        height
      }
    }
  }
`
